import { Route } from '@angular/router';

export const frameworkContractDetailsRoute: Route = {
    loadComponent: () => import('./framework-contract-details.component'),
    children: [
        // TODO: Redirect to overview if we have that ported
        { path: '', redirectTo: 'linked-contracts', pathMatch: 'full' },
        {
            path: 'linked-contracts',
            loadComponent: () => import('./linked-contracts-tab/linked-contracts-tab.component'),
        },
        {
            path: 'documents',
            loadComponent: () => import('./documents-tab/documents-tab.component'),
        },
        {
            path: 'notifications',
            loadComponent: () => import('./notifications-tab/notifications-tab.component'),
        },
    ],
};
